<template>
  <div id="roles-edit">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-title></v-card-title>
        <v-card-text>
          <p></p>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
          </div>
          <v-form ref="form1" v-model="valid1" lazy-validation v-on:submit.prevent="submitForm">
            <v-container>
              <v-row wrap>
                <v-col cols="12" md="12">
                  <p>
                    <span class="text-danger">*</span> indicates required
                    field
                  </p>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Role Name
                    </h6>
                  </label>
                  <v-text-field
                    v-model="RoleName"
                    :rules="RoleNameRules"
                    placeholder="Role Name"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      <span class="text-danger">*</span> Role Home Page
                    </h6>
                  </label>
                  <v-text-field
                    v-model="RoleHomePage"
                    :rules="RoleHomePageRules"
                    placeholder="Role HomePage"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      National Designation
                      <small>(optional)</small>
                    </h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    v-model="NationalDesignation"
                    :rules="NationalDesignationRules"
                    :items="NationalDesignationOptions"
                    :loading="NationalDesignationOptionsLoading"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      Zone Designation
                      <small>(optional)</small>
                    </h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    v-model="ZoneDesignation"
                    :rules="ZoneDesignationRules"
                    :items="ZoneDesignationOptions"
                    :loading="ZoneDesignationOptionsLoading"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      Lom Designation
                      <small>(optional)</small>
                    </h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    v-model="LomDesignation"
                    :rules="LomDesignationRules"
                    :items="LomDesignationOptions"
                    :loading="LomDesignationOptionsLoading"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      SMA NGP Designation
                      <small>(optional)</small>
                    </h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    v-model="SmaNgpDesignation"
                    :rules="SmaNgpDesignationRules"
                    :items="SmaNgpDesignationOptions"
                    :loading="SmaNgpDesignationOptionsLoading"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="3" md="3">
                  <label>
                    <h6>
                      SMA ZGP Designation
                      <small>(optional)</small>
                    </h6>
                  </label>
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    v-model="SmaZgpDesignation"
                    :rules="SmaZgpDesignationRules"
                    :items="SmaZgpDesignationOptions"
                    :loading="SmaZgpDesignationOptionsLoading"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="submitForm"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    small
                    color="#1db954"
                    class="font-size-h6 px-10 mr-3 white--text"
                  >Submit</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    EditRecordPrompt: {
      type: Boolean,
      required: true
    },
    recordData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SubmitFlag: false,
      ResultFlag: false,

      NationalDesignationRules: [],
      NationalDesignation: "",
      NationalDesignationOptions: [],
      NationalDesignationOptionsLoading: false,

      ZoneDesignationRules: [],
      ZoneDesignation: "",
      ZoneDesignationOptions: [],
      ZoneDesignationOptionsLoading: false,

      LomDesignationRules: [],
      LomDesignation: "",
      LomDesignationOptions: [],
      LomDesignationOptionsLoading: false,

      SmaNgpDesignationRules: [],
      SmaNgpDesignation: "",
      SmaNgpDesignationOptions: [],
      SmaNgpDesignationOptionsLoading: false,

      SmaZgpDesignationRules: [],
      SmaZgpDesignation: "",
      SmaZgpDesignationOptions: [],
      SmaZgpDesignationOptionsLoading: false,

      RoleDesignationCode: "",
      RoleCode: "",
      RoleName: "",
      RoleNameRules: [v => !!v || "Role Name is required"],

      RoleHomePage: "/my-profile",
      RoleHomePageRules: [v => !!v || "Role Home Page is required"],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: ""
    };
  },
  computed: {},
  watch: {
    PageInfo: function() {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;
        this.CurrentYearId = CurrentJciYearId;

        console.log("recordData=" + JSON.stringify(this.recordData));

        this.RoleDesignationCode =
          this.recordData.RolesDesignationId == null
            ? ""
            : this.recordData.RolesDesignationId;
        this.RoleCode =
          this.recordData.RolesId == null ? "" : this.recordData.RolesId;
        this.RoleName =
          this.recordData.RolesName == null ? "" : this.recordData.RolesName;
        this.NationalDesignation =
          this.recordData.NationalDesignationCode == null
            ? ""
            : this.recordData.NationalDesignationCode;
        this.ZoneDesignation =
          this.recordData.ZoneDesignationCode == null
            ? ""
            : this.recordData.ZoneDesignationCode;
        this.LomDesignation =
          this.recordData.LomDesignationCode == null
            ? ""
            : this.recordData.LomDesignationCode;
        this.SmaNgpDesignation =
          this.recordData.SMANGPDesignationCode == null
            ? ""
            : this.recordData.SMANGPDesignationCode;
        this.SmaZgpDesignation =
          this.recordData.SMAZGPDesignationCode == null
            ? ""
            : this.recordData.SMAZGPDesignationCode;

        this.getDesignationCodeOptions(
          "NationalDesignation",
          "NationalDesignationOptions",
          1
        );
        this.getDesignationCodeOptions(
          "ZoneDesignation",
          "ZoneDesignationOptions",
          2
        );
        this.getDesignationCodeOptions(
          "LomDesignation",
          "LomDesignationOptions",
          3
        );
        this.getDesignationCodeOptions(
          "SmaNgpDesignation",
          "SmaNgpDesignationOptions",
          4
        );
        this.getDesignationCodeOptions(
          "SmaZgpDesignation",
          "SmaZgpDesignationOptions",
          5
        );
      }
    },
    NationalDesignationOptions: function() {
      console.log("watch NationalDesignationOptions");
      this.LoadingFlag = false;
    },
    ZoneDesignationOptions: function() {
      console.log("watch ZoneDesignationOptions");
      this.LoadingFlag = false;
    },
    LomDesignationOptions: function() {
      console.log("watch LomDesignationOptions");
      this.LoadingFlag = false;
    },
    SmaNgpDesignationOptions: function() {
      console.log("watch SmaNgpDesignationOptions");
      this.LoadingFlag = false;
    },
    SmaZgpDesignationOptions: function() {
      console.log("watch SmaZgpDesignationOptions");
      this.LoadingFlag = false;
    }
  },
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "roles",
        Action: "edit"
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog",this.ResultFlag);
    },
    getDesignationCodeOptions(source, destination, OrganizationType) {
      console.log("DesignationCodeOptions called");
      this.LoadingFlag = true;
      var selectbox1_source = source;
      var selectbox1_destination = destination;
      var selectbox1_url = "api/designation/options";
      var selectbox1_conditions_array = {
        UserInterface: 3,
        OrganizationTypeId: OrganizationType,
        ExceptDesignationId: [],
        AllOption: 0
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

        console.log("RoleDesignationCode=" + this.RoleDesignationCode);

      if (validate1) {
        var upload = {
          UserInterface: 1,
          RoleDesignation: this.RoleDesignationCode,
          Role: this.RoleCode,
          RoleName: this.RoleName,
          RoleHomePage: this.RoleHomePage,
          NationalDesignation: this.NationalDesignation,
          ZoneDesignation: this.ZoneDesignation,
          LomDesignation: this.LomDesignation,
          SmaNgpDesignation: this.SmaNgpDesignation,
          SmaZgpDesignation: this.SmaZgpDesignation
        };
        console.log({ upload });

        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/roles/update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.ResultFlag = true;
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.progessStart = 0;
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.sweetAlert("error", message, false);
      }
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
#roles-edit {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>